<template>
  <div class="copyTrade">
    <div class="copyTrade-tab">
      <div class="listTab">
        <b-button
          class="btnNoStyle tab-item"
          :class="activeTab === 'tradeExpert' ? 'activeTab' : ''"
          @click="activeTab = 'tradeExpert'"
          >Trade expert</b-button
        >
        <b-button
          class="btnNoStyle tab-item"
          :class="activeTab === 'tradeWallet' ? 'activeTab' : ''"
          @click="activeTab = 'tradeWallet'"
          >Copy trade wallet</b-button
        >
        <b-button
          class="btnNoStyle tab-item"
          :class="activeTab === 'tradeSignUp' ? 'activeTab' : ''"
          @click="activeTab = 'tradeSignUp'"
          >Sign up for an expert</b-button
        >
      </div>
    </div>
    <div class="copyTrade-content" v-if="false">
      <TradeExpert v-if="activeTab === 'tradeExpert'" />
      <TradeWallet v-if="activeTab === 'tradeWallet'" />
      <TradeSignUp v-if="activeTab === 'tradeSignUp'" />
    </div>
    <div class="copyTrade-content">
      <h3 class="text-center text-comingSoon">{{ $t('app.coming_soon') }}</h3>
    </div>
  </div>
</template>

<script>
import TradeExpert from '@/components/CopyTrade/TradeExpert.vue';
import TradeWallet from '@/components/CopyTrade/TradeWallet.vue';
import TradeSignUp from '@/components/CopyTrade/TradeSignUp.vue';

export default {
  name: 'CopyTradeViewPage',
  components: {
    TradeExpert,
    TradeWallet,
    TradeSignUp,
  },
  data() {
    return {
      activeTab: 'tradeExpert',
    };
  },
};
</script>

<style lang="scss">
.copyTrade {
  padding: 30px 50px;
  @media (max-width: 992px) {
    padding: 30px 20px;
  }
  .copyTrade-tab {
    padding: 5px;
    background: rgba(27, 72, 187, 0.2);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(34px);
    border-radius: 7px;
    width: fit-content;
    margin-bottom: 40px;
    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 576px) {
      width: 100%;
    }
    .listTab {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tab-item {
        padding: 0px 25px;
        height: 55px;
        @media (max-width: 576px) {
          min-width: 130px;
        }
        &.activeTab {
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          border-radius: 6px;
        }
      }
    }
  }
  .copyTrade-content {
    .text-comingSoon {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 30px;
      font-weight: bold;
    }
  }
}
</style>