<template>
    <div class="tradeWallet">
        <b-row class="tradeWallet-statistic">
            <b-col sm="6" md="4" lg="4" xl="4" class="mb-4 mb-sm-4 mb-md-0">
                <div class="tradeWallet-statistic-item">
                    <h3>Balance Package</h3>
                    <div class="amount">
                        <p>0 USD</p>
                        <img :src="require('@/assets/images/currency/usd-large.png')" alt="currency" width="20px" />
                    </div>
                </div>
            </b-col>
            <b-col sm="6" md="4" lg="4" xl="4" class="mb-4 mb-sm-4 mb-md-0">
                <div class="tradeWallet-statistic-item">
                    <h3>Balance Profit</h3>
                    <div class="amount">
                        <p>0 USD</p>
                        <img :src="require('@/assets/images/currency/usd-large.png')" alt="currency" width="20px" />
                    </div>
                    <b-button class="btnNoStyle btn-profitWithdraw"
                        @click="$bvModal.show('withdraw-profit')">Withdraw</b-button>
                </div>
            </b-col>
            <b-col sm="6" md="4" lg="4" xl="4" class="mb-4 mb-sm-4 mb-md-0">
                <div class="tradeWallet-statistic-item">
                    <h3>Net Profit</h3>
                    <div class="amount">
                        <p>0 USD</p>
                        <img :src="require('@/assets/images/currency/usd-large.png')" alt="currency" width="20px" />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row class="history">
            <div class="history-tab">
                <div class="listTab">
                    <b-button class="btnNoStyle tab-item" :class="activeTab === 'profitHistory' ? 'activeTab' : ''"
                        @click="activeTab = 'profitHistory'">Profit History</b-button>
                    <b-button class="btnNoStyle tab-item" :class="activeTab === 'followHistory' ? 'activeTab' : ''"
                        @click="activeTab = 'followHistory'">Follow History</b-button>
                </div>
            </div>
            <!-- Profit History -->
            <template v-if="activeTab === 'profitHistory'">
                <div class="table-responsive historyTable" v-if="!showTableMobile">
                    <b-table :items="data" :fields="fields" show-empty class="customTable">
                        <template #empty="">
                            <h5 class="text-center p-2">
                                {{ $t('support.no_data_available_in_table') }}
                            </h5>
                        </template>
                        <template #cell(amount)="data">
                            <div class="d-flex align-items-center justify-content-center">
                                <img :src="require('@/assets/images/currency/usd-large.png')" alt="currency" width="25px"
                                    class="mr-2" />
                                {{ TruncateToDecimals2(data.item.amount, '', 2) }}
                            </div>
                        </template>
                    </b-table>
                </div>
                <div class="table-mobile" v-else>
                    <div v-for="(history, idx) in data" :key="'profitHistory: ' + history.id" class="w-100" role="tablist">
                        <b-button v-b-toggle="`profitHistory-${idx}`" class="btnNoStyle header" role="tab">{{ history.expert
                        }}</b-button>
                        <b-collapse :id="`profitHistory-${idx}`" class="mt-2" accordion="my-accordion" role="tabpanel">
                            <p>- Amount: {{ TruncateToDecimals2(history.amount, '', 2) }}</p>
                            <p>- Time: {{ history.time }}</p>
                        </b-collapse>
                    </div>
                </div>
                <div class="d-flex w-100 justify-content-end align-items-center">
                    <paginate :totalPage="3" :perPage="1" @current="onChangePageProfitHistory" />
                </div>
            </template>
            <!-- End Profit History -->
            <!-- Follow History -->
            <template v-if="activeTab === 'followHistory'">
                <div class="table-responsive historyTable">
                    <b-table :items="data" :fields="fields" show-empty class="customTable" v-if="!showTableMobile">
                        <template #empty="">
                            <h5 class="text-center p-2">
                                {{ $t('support.no_data_available_in_table') }}
                            </h5>
                        </template>
                        <template #cell(amount)="data">
                            <div class="d-flex align-items-center justify-content-center">
                                <img :src="require('@/assets/images/currency/usd-large.png')" alt="currency" width="25px"
                                    class="mr-2" />
                                {{ TruncateToDecimals2(data.item.amount, '', 2) }}
                            </div>
                        </template>
                    </b-table>
                    <div class="table-mobile" v-else>
                        <div v-for="(history, idx) in data" :key="'followHistory: ' + history.id" class="w-100"
                            role="tablist">
                            <b-button v-b-toggle="`profitHistory-${idx}`" class="btnNoStyle header" role="tab">{{
                                history.expert }}</b-button>
                            <b-collapse :id="`profitHistory-${idx}`" class="mt-2" accordion="my-accordion" role="tabpanel">
                                <p>
                                    - Amount: {{ TruncateToDecimals2(history.amount, '', 2) }}
                                </p>
                                <p>- Time: {{ history.time }}</p>
                            </b-collapse>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-100 justify-content-end align-items-center">
                    <Paginate :totalPage="3" :perPage="1" @current="onChangePageFollowHistory" />
                </div>
            </template>
            <!-- End Follow History -->
        </b-row>
        <!-- Modal Withdraw Profit -->
        <!-- Modal Details -->
        <b-modal id="withdraw-profit" hide-footer hide-header>
            <div class="header-modal">
                <h3>Withdraw Profit</h3>
                <img :src="require('@/assets/images/icons/close-white.svg')" alt=""
                    @click="$bvModal.hide('withdraw-profit')" class="close" />
            </div>
            <div class="form-group mt-4">
                <label for="">Balance Profit</label>
                <b-form-input disabled value="25,000.00" class="custom-input"></b-form-input>
            </div>
            <div class="form-group mt-4">
                <label for="">Amount Profit</label>
                <b-form-input disabled value="10" class="custom-input"></b-form-input>
            </div>
            <div class="form-group mt-4">
                <label for="">Auth</label>
                <b-form-input disabled value="25" class="custom-input"></b-form-input>
            </div>
            <div class="form-group text-center d-flex justify-content-center mt-5">
                <b-button class="btnNoStyle btn-profitWithdraw" type="submit">
                    Withdraw
                </b-button>
            </div>
        </b-modal>
        <!-- End Modal Withdraw Profit -->
    </div>
</template>

<script>
import Paginate from '@/components/Shared/Pagination.vue';

export default {
    name: 'TradeWalletViewPage',
    components: {
        Paginate,
    },
    data() {
        return {
            activeTab: 'profitHistory',
            fields: [
                {
                    key: 'expert',
                    label: 'Name Of Expert',
                    class: 'text-center',
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    class: 'text-center',
                },
                {
                    key: 'time',
                    label: 'Time',
                    class: 'text-center',
                },
            ],
            data: [
                {
                    id: 1,
                    expert: '1192374561546467167346356547',
                    amount: 2000000,
                    time: '20:42:16',
                },
                {
                    id: 2,
                    expert: '1192374561546467167346356547',
                    amount: 2000000,
                    time: '20:42:16',
                },
                {
                    id: 3,
                    expert: '1192374561546467167346356547',
                    amount: 2000000,
                    time: '20:42:16',
                },
                {
                    id: 4,
                    expert: '1192374561546467167346356547',
                    amount: 2000000,
                    time: '20:42:16',
                },
                {
                    expert: '1192374561546467167346356547',
                    amount: 2000000,
                    time: '20:42:16',
                },
            ],
            widthL: 0,
            showTableMobile: false,
        };
    },
    methods: {
        onChangePageFollowHistory(payload) {
            // console.log(payload);
        },
        onChangePageProfitHistory(payload) {
            // console.log(payload);
        },
        checkResizeWindow() {
            this.widthL = window.innerWidth;
        },
    },
    mounted() {
        this.checkResizeWindow();
        this.windowEvent = () => {
            this.checkResizeWindow();
        };
        if (this.widthL <= 576) {
            // console.log(this.widthL);
            this.showTableMobile = true;
        } else {
            this.showTableMobile = false;
        }
    },
};
</script>

<style lang="scss">
.tradeWallet {
    .tradeWallet-statistic {
        margin-bottom: 30px;

        .tradeWallet-statistic-item {
            background: url('~@/assets/images/bg-start.png'),
                linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
            background-size: cover;
            border-radius: 4px;
            padding: 20px 20px 30px;
            height: 100%;

            h3 {
                font-size: 20px;
                margin-bottom: 20px;
            }

            .amount {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;

                p {
                    font-weight: bold;
                    font-size: 30px;
                    margin-bottom: 0px;
                    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }

                img {
                    width: 45px;
                    height: auto;
                }
            }


        }
    }

    .history {
        background: linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
        border-radius: 3px;
        padding: 30px;

        .history-tab {
            padding: 5px;
            background: rgba(27, 72, 187, 0.2);
            box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(34px);
            border-radius: 7px;
            width: fit-content;
            margin-bottom: 40px;
            overflow-x: auto;
            overflow-y: hidden;

            @media (max-width: 576px) {
                width: 100%;
            }

            .listTab {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .tab-item {
                    padding: 0px 25px;
                    height: 55px;

                    @media (max-width: 576px) {
                        min-width: 130px;
                    }

                    &.activeTab {
                        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
                        border-radius: 6px;
                    }
                }
            }
        }

        .historyTable {}

        .table-mobile {
            width: 100%;

            .header {
                width: 100%;
                background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
                border-radius: 6px;
                padding: 0px 25px;
                height: 55px;
                margin-bottom: 20px;
            }

            .collapse {
                margin-top: -20px !important;
                padding: 20px 10px;
                box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
                -webkit-backdrop-filter: blur(34px);
                backdrop-filter: blur(34px);
                border-radius: 7px;
                margin-bottom: 20px;

                P {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

.btn-profitWithdraw {
    width: 100%;
    height: 50px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    border-radius: 3px;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        filter: blur(21px);
        z-index: -1;
    }
}

@media (max-width: 1200px) {
    .tradeWallet {
        .tradeWallet-statistic {
            .tradeWallet-statistic-item {
                h3 {
                    font-size: 16px;
                }

                .amount {
                    p {
                        font-size: 22px;
                    }

                    img {
                        width: 30px;
                    }
                }

                .btn-profitWithdraw {
                    height: 40px;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .tradeWallet {
        .tradeWallet-statistic {
            .tradeWallet-statistic-item {
                h3 {}

                .amount {
                    p {}

                    img {}
                }

                .btn-profitWithdraw {}
            }
        }
    }
}

@media (max-width: 768px) {
    .tradeWallet {
        .tradeWallet-statistic {
            .tradeWallet-statistic-item {
                h3 {}

                .amount {
                    p {}

                    img {}
                }

                .btn-profitWithdraw {}
            }
        }
    }
}

@media (max-width: 576px) {
    .tradeWallet {
        .tradeWallet-statistic {
            .tradeWallet-statistic-item {
                h3 {}

                .amount {
                    p {}

                    img {}
                }

                .btn-profitWithdraw {}
            }
        }
    }
}

@media (max-width: 376px) {
    .tradeWallet {
        .tradeWallet-statistic {
            .tradeWallet-statistic-item {
                h3 {}

                .amount {
                    p {}

                    img {}
                }

                .btn-profitWithdraw {}
            }
        }
    }
}
</style>