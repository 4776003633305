import { render, staticRenderFns } from "./PrivateCopyTrade.vue?vue&type=template&id=40f9187a"
import script from "./PrivateCopyTrade.vue?vue&type=script&lang=js"
export * from "./PrivateCopyTrade.vue?vue&type=script&lang=js"
import style0 from "./PrivateCopyTrade.vue?vue&type=style&index=0&id=40f9187a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports