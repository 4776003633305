<!-- eslint-disable vue/valid-v-on -->
<template>
  <div class="tradeExpert">
    <!-- Top Profit -->
    <div class="tradeExpert-header">
      <h3>Top profit</h3>
      <div class="line"></div>
    </div>
    <b-row
      class="tradeExpert-list"
      v-if="Expert.data && Expert.data.length >= 1"
    >
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="4"
        v-for="expert in Expert.data"
        :key="'tradeExpert: ' + expert.id"
      >
        <div class="tradeExpert-item">
          <div class="tradeExpert-item-avatar">
            <div class="box-avatar">
              <img v-lazy="expert.img" alt="" />
            </div>
          </div>
          <div class="tradeExpert-item-info">
            <h3 class="title">{{ expert.name }}</h3>
            <div class="d-flex">
              <div class="tag mr-2">
                <img src="~@/assets/images/icons/icon-start.png" alt="" />
                <p>Expert</p>
              </div>
              <div
                class="tag"
                @click="getDetailExpert(expert)"
                style="cursor: pointer"
              >
                <p>Details</p>
              </div>
            </div>
            <hr />
            <div class="statistic">
              <div class="statistic-item">
                <h3 class="profit">{{ Math.floor(expert.profitShow) }}%</h3>
                <p>Profit (30D)</p>
              </div>
              <div class="statistic-item">
                <h3>{{ expert.copier || 0 }}</h3>
                <p>copiers</p>
              </div>
              <div
                class="statistic-item"
                v-if="List.followList && List.followList[UserInfo.userId]"
              >
                <h3>
                  {{ expert.profit }}
                </h3>
                <p>profits sharing</p>
              </div>
            </div>
            <b-button
              class="btnNoStyle btn-startCopy"
              @click="$bvModal.show('followMD')"
            >
              Start Copying
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- End Top Profit -->
    <!-- Modal Details -->
    <b-modal id="detail-expert" hide-footer hide-header>
      <template v-if="expertDetail && expertDetail.balance">
        <div class="header-modal">
          <h3>{{ $t("ticket.detail") + ": " + expertDetail.name }}</h3>
          <img
            :src="require('@/assets/images/icons/close-white.svg')"
            alt=""
            @click="$bvModal.hide('detail-expert')"
            class="close"
          />
        </div>
        <div class="form-group mt-4">
          <label for="">Total Follow (USDT)</label>
          <b-form-input
            disabled
            :value="expertDetail.balance"
            class="custom-input"
            readonly
          ></b-form-input>
        </div>
        <div
          class="form-group mt-4"
          v-for="(value, key) in expertDetail.percent_profit"
          :key="key"
        >
          <label for="">
            Profit Per
            {{ key.charAt(0).toUpperCase() + key.slice(1) }} (%)
          </label>
          <b-form-input
            disabled
            :value="value"
            class="custom-input"
          ></b-form-input>
        </div>
      </template>
    </b-modal>
    <!-- End Modal Details -->
    <!-- Modal Copy Trade -->
    <b-modal id="followMD" class="m-0" hide-footer hide-header>
      <div class="header-modal">
        <h3>{{ $t("community.modal.title") + ": " + "Mr.Tu (1)" }}</h3>
        <img
          :src="require('@/assets/images/icons/close-white.svg')"
          alt=""
          @click="$bvModal.hide('followMD')"
          class="close"
        />
      </div>
      <div class="content-follow">
        <div class="img-package">
          <b-button
            variant="primary"
            class="btn-aktrade prev"
            :disabled="currentPackage <= 1"
            @click="onNextPack(currentPackage - 1)"
          >
            <i class="far fa-chevron-circle-left"></i>
          </b-button>
          <b-button
            variant="primary"
            class="btn-aktrade next"
            :disabled="currentPackage >= 5"
            @click="onNextPack(currentPackage + 1)"
          >
            <i class="far fa-chevron-circle-right"></i>
          </b-button>
          <div class="box-image">
            <img
              :src="require(`@/assets/images/copytrade/${currentPackage}.png`)"
              alt=""
            />
          </div>
        </div>

        <div class="form-group text-center d-flex justify-content-center">
          <p class="text-primary">
            <span class="text-danger">*</span>
            {{ $t(`community.modal.form.warning${currentPackage}`) }}
            <span class="text-danger">*</span>
          </p>
        </div>
        <form @submit.prevent="onFollowing()">
          <div class="form-group">
            <label for="">{{ $t("wallet.main_balance") }}</label>
            <b-form-input
              class="form-control custom-input"
              readonly
              :value="truncateToDecimals(UserBalance.MainBalance)"
            />
          </div>
          <div class="form-group mt-4">
            <label for="">{{ $t("community.modal.form.amount") }}</label>
            <b-form-input
              v-model.number="follow.amount"
              class="form-control custom-input"
              placeholder="Enter Your Amount"
              @keyup="setFee()"
              @change="onMin()"
            />
          </div>
          <div class="form-group mt-4">
            <label for="">{{ $t("community.modal.form.time") }}</label>
            <select
              class="form-control custom-input"
              v-model="follow.day"
              @change="setFee()"
              v-if="feeCopyTrade"
            >
              <option
                :value="list.id"
                v-for="list in feeCopyTrade"
                :key="list.id"
              >
                {{ list.name }}
              </option>
            </select>
          </div>
          <div
            class="form-group mt-4"
            v-if="follow.day !== 1 && follow.day !== 0"
          >
            <b-form-checkbox
              id="checkbox-1"
              v-model="follow.fee"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              @change="setFee()"
            >
              <span>{{ $t("community.modal.form.insurance") }}</span>
            </b-form-checkbox>
          </div>
          <div class="form-group mt-4" v-if="follow.fee">
            <label for="">{{ $t("community.modal.form.fee") }}</label>
            <input
              :value="truncateToDecimals(follow.feeAmount)"
              type="text"
              class="form-control custom-input"
              placeholder="Your Fee"
              readonly
            />
          </div>
          <div class="form-group mt-4">
            <label for="">{{ $t("community.modal.form.payout") }}</label>
            <input
              :value="truncateToDecimals(follow.payment)"
              type="text"
              class="form-control custom-input"
              readonly
            />
          </div>
          <div class="form-group text-center d-flex justify-content-center">
            <b-button
              variant="primary"
              class="btn btn-aktrade"
              type="submit"
              :disabled="
                follow.amount < 210 || follow.payment > UserBalance.MainBalance
              "
            >
              {{ $t("community.profile.copy") }}
            </b-button>
          </div>
        </form>
      </div>
    </b-modal>
    <!-- End Modal Copy Trade -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TradeExpertViewPage",
  data() {
    return {
      currentPackage: 1,
      follow: {
        day: 0,
        amount: 0,
        fee: false,
        payment: 0,
        expert: null,
        name: "",
        token: null,
        feeAmount: 0,
      },
      expertDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      List: "copytrade/List",
      UserBalance: "userinfo/UserBalance",
      UserInfo: "userinfo/UserInfo",
    }),
    feeCopyTrade() {
      return this.List.feeCopyTrade;
    },
    Expert() {
      return this.List.Expert || [];
    },
  },
  methods: {
    onNextPack(pos) {
      if (pos <= 0) {
        this.currentPackage = 1;
        return;
      }
      if (pos > 5) {
        this.currentPackage = 5;
        return;
      }
      this.currentPackage = pos;
    },
    onGetList: function (page) {
      this.$store.dispatch("copytrade/req_getFollowList", { page: page });
    },
    setFee() {
      if (this.follow.fee) {
        this.follow.feeAmount =
          this.follow.amount * this.listTime[this.follow.day].fee;
        this.follow.payment =
          this.follow.amount * this.listTime[this.follow.day].fee +
          this.follow.amount;
      } else {
        this.follow.feeAmount = 0;
        this.follow.payment = this.follow.amount;
      }
      if (this.follow.amount <= 2999) {
        this.currentPackage = 1;
      }
      if (this.follow.amount >= 3000 && this.follow.amount <= 4999) {
        this.currentPackage = 2;
      }
      if (this.follow.amount >= 5000 && this.follow.amount <= 9999) {
        this.currentPackage = 3;
      }
      if (this.follow.amount >= 10000 && this.follow.amount <= 19999) {
        this.currentPackage = 4;
      }
      if (this.follow.amount >= 20000) {
        this.currentPackage = 5;
      }
    },
    onMin() {
      if (this.follow.amount < 210) {
        this.$toastr.e(
          "Please Check Minimum Amount Follow , Min follow is $ 210"
        );
      }
    },
    onFollowing: async function () {
      // console.log("abc");
      //   const token = await this.genarateCaptChaV3("trade");
      //   if (!token) {
      //     this.$toastr.e("Too Fast! Please Wating Recaptcha.", "Withdraw Failed");
      //     return;
      //   }
      //   if (
      //     this.user.walletList[0].balance < this.follow.amount ||
      //     this.follow.amount < 210
      //   ) {
      //     this.$toastr.e("Your Balance Is Not Enough", "Withdraw Failed");
      //     return;
      //   }
      //   this.follow.token = await token;
      //   await this.$store.dispatch("copytrade/req_postFollow", this.follow);
    },
    truncateToDecimals(num, dec = 2) {
      var calcDec = Math.pow(10, dec);
      var newNum = Math.trunc(num * calcDec) / calcDec;
      return `$ ${newNum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    getDetailExpert(expert) {
      this.expertDetail = expert;
      this.$bvModal.show("detail-expert");
    },
  },
  created() {
    this.onGetList(1);
  },
};
</script>

<style lang="scss" scoped>
.tradeExpert {
  .tradeExpert-header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    h3 {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 18px;
      margin-right: 20px;
      margin-bottom: 0px;
      width: 100px;
    }

    .line {
      width: 100%;
      height: 1px;
      background: rgba(93, 184, 251, 0.42);
    }
  }

  .tradeExpert-list {
    .tradeExpert-item {
      background: linear-gradient(
        180deg,
        #081e43 0%,
        rgba(1, 6, 15, 0.63) 100%
      );
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .tradeExpert-item-avatar {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        .box-avatar {
          padding: 5px;
          width: 130px;
          height: 130px;
          border-radius: 50%;
          border: 1px solid #00c2ff;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
          }
        }
      }

      .tradeExpert-item-info {
        width: 68%;

        .tag {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background: #153184;
          border-radius: 5px;
          width: fit-content;
          padding: 5px 15px;

          img {
            margin-right: 10px;
          }

          p {
            margin-bottom: 0px;
            font-weight: 600;
          }
        }

        hr {
          width: 100%;
          height: 1px;
          background: rgba(93, 184, 251, 0.42);
        }

        .statistic {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .statistic-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .profit {
              background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }

        .btn-startCopy {
          width: 100%;
          height: 50px;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          border-radius: 3px;
          position: relative;
          z-index: 1;

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .tradeExpert {
    .tradeExpert-list {
      .tradeExpert-item {
        .tradeExpert-item-avatar {
          .box-avatar {
            width: 100px;
            height: 100px;
          }
        }

        .tradeExpert-item-info {
          .title {
            font-size: 20px;
          }

          .tag {
            padding: 5px 10px;

            img {
              margin-right: 5px;
            }

            p {
              font-size: 14px;
            }
          }

          .statistic {
            .statistic-item {
              h3 {
                font-size: 22px;
                margin-bottom: 5px;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tradeExpert {
    .tradeExpert-list {
      .tradeExpert-item {
        flex-direction: column;
        justify-content: center;
        padding: 20px 10px;

        .tradeExpert-item-avatar {
          width: 100%;

          .box-avatar {
          }
        }

        .tradeExpert-item-info {
          width: 100%;
          text-align: center;

          .title {
            margin-top: 15px;
          }

          .tag {
            margin: 15px auto 0px;

            img {
            }

            p {
            }
          }

          .statistic {
            .statistic-item {
              h3 {
              }

              p {
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .tradeExpert {
    .tradeExpert-list {
      .tradeExpert-item {
        width: 250px;
        margin: 0 auto 30px;

        .tradeExpert-item-avatar {
          .box-avatar {
          }
        }

        .tradeExpert-item-info {
          .title {
          }

          .tag {
            img {
            }

            p {
            }
          }

          .statistic {
            .statistic-item {
              h3 {
              }

              p {
              }
            }
          }
        }
      }
    }
  }
}

#followMD {
  .content-follow {
    position: relative;

    .img-package {
      position: relative;

      .box-image {
        width: 150px;
        height: 150px;
        background: #ffffff0a;
        border-radius: 50%;
        margin: 5px auto;

        img {
          width: 100%;
          height: 100%;
          filter: drop-shadow(2px 4px 6px black);
        }
      }

      .prev,
      .next {
        position: absolute;
        width: 35px !important;
        height: 35px !important;
        top: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        color: #222533 !important;
      }

      .prev {
        left: 0;
        right: auto;
      }

      .next {
        left: auto;
        right: 0;
      }
    }
  }

  .btn-aktrade {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    background: linear-gradient(
      91.46deg,
      #0ec5ff 1.08%,
      #3964fb 83%
    ) !important;
    border-radius: 3px;
    position: relative;
    z-index: 1;

    // &::before {
    //   position: absolute;
    //   content: '';
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 50px;
    //   background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
    //   filter: blur(21px);
    //   z-index: -1;
    // }
  }
}
</style>
