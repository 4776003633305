<template>
  <div class="tradeSignUp">
    <b-row class="align-items-center">
      <b-col md="6" lg="6" xl="6">
        <div class="tradeSignUp-image">
          <img src="~@/assets/images/mockup/rocket.png" alt="" />
        </div>
      </b-col>
      <b-col md="6" lg="6" xl="6">
        <div class="tradeSignUp-info">
          <h3>BECOME AN EXPERT IN SOCIAL TRADE NOW</h3>
          <p>
            NUMBER OF RECENT 30-DAY TRANSACTIONS: 100 TRANSACTIONS (0 / 100)
          </p>
          <p>RECENT 30-DAY TRADING VOLUME: $ 5,000 (0$ / 5000$)</p>
          <p>RECENT 30-DAY PROFIT: $ 1,000 (0$ / 1000$)</p>
          <b-button class="btnNoStyle btn-signUp" @click="showFormSignUpExpert = !showFormSignUpExpert">Sign Up</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="formSignUp" v-if="showFormSignUpExpert">
      <b-col md="6" lg="6" xl="6">
        <h3>SIGN UP TO BECOME AN EXPERT</h3>
        <div class="avatar">
          <p class="labelInput">AVATAR</p>
          <div class="avatarEmpty text-center" v-if="!avatar || avatar == null">
            <input @change="onFileChange($event, 0)" type="file" accept=".jpg,.jpeg,.png" class="fileUpload" />
            <svg data-v-de2bfc3e="" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 89.156 95.1">
              <g data-v-de2bfc3e="" id="upload-and-send" transform="translate(0 0)" opacity="0.3">
                <path data-v-de2bfc3e="" id="Path_34910" data-name="Path 34910"
                  d="M25.038,1.016a2.972,2.972,0,0,1,4.476,0l20.8,23.775a2.972,2.972,0,0,1-2.241,4.927H36.188V68.353a2.972,2.972,0,0,1-2.972,2.972H21.329a2.972,2.972,0,0,1-2.972-2.972V29.719H6.469a2.972,2.972,0,0,1-2.235-4.927Z"
                  transform="translate(17.306 0)" fill="#fefefe"></path>
                <path data-v-de2bfc3e="" id="Path_34911" data-name="Path 34911"
                  d="M77.269,11V28.831H11.888V11H0V34.775a5.944,5.944,0,0,0,5.944,5.944H83.213a5.944,5.944,0,0,0,5.944-5.944V11Z"
                  transform="translate(0 54.381)" fill="#fefefe"></path>
              </g>
            </svg>
          </div>
          <div v-else>
            <div class="select-images-box d-flex flex-column position-relative">
              <cropper class="cropper" style="z-index: 100" :src="avatar"
                :stencil-component="$options.components.CircleStencil" @change="change"></cropper>
            </div>
            <div class="d-flex justify-content-center align-items-center submit col-12">
              <b-button class="btnNoStyle btn-uploadAvatar mt-3" v-if="avatar" @click="removeImage(0)">
                UP LOAD
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div class="checkBoxIcon">
            <img v-if="isPolicyChecked.real" src="~@/assets/images/community/checked.png" alt="" class="check" />
            <img v-else src="~@/assets/images/community/unchecked.png" alt="" class="uncheck" @click="onShowPolicy" />
          </div>
          <div class="policy-text">
            {{ $t('expert.Commitment') }}
          </div>
        </div>
      </b-col>
      <b-col md="6" lg="6" xl="6">
        <div class="form-content">
          <div class="form-group">
            <p class="labelInput">NAME:</p>
            <b-form-input id="name-input2" v-model="register.name" required class="custom-input"></b-form-input>
          </div>
          <div class="form-group">
            <p class="labelInput">COPY TIME:</p>
            <vue-timepicker v-if="InfoExpert.timeCopyTrade" v-model.trim="register.timeFrom" :hour-range="[
              [InfoExpert.timeCopyTrade.from, InfoExpert.timeCopyTrade.to],
            ]" hide-disabled-hours hide-disabled-minutes close-on-complete format="HH" hour-label="Time "
              input-width="100%">
            </vue-timepicker>
          </div>
          <div class="form-group">
            <p class="labelInput">
              PROFITS RECEIVED FROM FOLLOWERS.(5% - 10%):
            </p>
            <b-form-input id="percent-input2" max="5" min="1" v-model.number="register.percent" required
              class="custom-input"></b-form-input>
          </div>
          <div class="d-flex justify-content-center">
            <b-button class="btnNoStyle btn-sendSignUp">SEND</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="policy" ref="policy" hide-header hide-footer modal-class="d-flex justify-content-center flex-column">
      <h4 class="text-center title">{{ $t('expert.Terms_to_register') }}</h4>
      <div @scroll="handleScroll" class="policy-div my-4">
        <p>
          {{ $t('expert.Ig_social_trading') }}
        </p>
        <p>
          {{ $t('expert.1') }}
          <br />
          {{ $t("expert['1.1']") }}
          <br />
          {{ $t("expert['1.2']") }}
          <br />
          {{ $t("expert['1.3']") }}
          <br />
          {{ $t("expert['1.4']") }}
        </p>
        <p>
          {{ $t('expert.2') }}
          <br />
          {{ $t("expert['2.1']") }}
          <br />
          {{ $t("expert['2.2']") }}
          <br />
          {{ $t("expert['2.3']") }}
          <br />
          {{ $t("expert['2.4']") }}
        </p>
        <p>
          {{ $t('expert.3') }}
          <br />
          {{ $t("expert['3.1']") }}
          <br />
          {{ $t("expert['3.2']") }}
          <br />
          {{ $t("expert['3.3']") }}
        </p>
        <p>
          {{ $t('expert.4') }}
          <br />
          {{ $t("expert['4.1']") }}
          <br />
          {{ $t("expert['4.2']") }}
          <br />
          {{ $t("expert['4.3']") }}
          <br />
          {{ $t("expert['4.4']") }}
          <br />
          {{ $t("expert['4.5']") }}
        </p>
        <div class="d-flex align-items-center upDownIcon justify-content-center" :key="`arrow_${isPolicyChecked.temp}`"
          v-if="!isPolicyChecked.temp">
          <i class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center text-center text-warning"
        :key="` See all${isPolicyChecked.temp}`" v-if="!isPolicyChecked.temp">
        {{ $t('expert.Scroll down and see all terms to confirm') }}
      </div>
      <div v-if="isPolicyChecked.temp" class="d-flex align-items-center justify-content-center">
        <b-button variant="primary" class="btn-aktrade" @click="onAccept()">
          {{ $t('expert.Register') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

export default {
  name: 'TradeSignUpViewPage',
  components: {
    VueTimepicker,
  },
  data() {
    return {
      reader: new FileReader(),
      avatar: null,
      register: {
        email: '',
        timeFrom: '',
        percent: 0,
        name: '',
        avatar: '',
      },
      showFormSignUpExpert: false,
      isPolicyChecked: {
        temp: false,
        real: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      InfoExpert: 'copytrade/InfoExpert',
    }),
  },
  methods: {
    onFileChange(e, type) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.reader.onload = (e) => {
        if (type == 0) {
          this.avatar = e.target.result;
        }
      };
      this.reader.readAsDataURL(files[0]);
    },
    removeImage(type) {
      if (type == 0) {
        this.avatar = '';
      }
    },
    change({ canvas }) {
      this.register.avatar = canvas.toDataURL();
    },
    onShowPolicy() {
      this.$bvModal.show('policy');
    },
    onAccept() {
      this.isPolicyChecked.real = true;
      this.$bvModal.hide('policy');
    },
    handleScroll: function (el) {
      if (
        el.target.offsetHeight + el.target.scrollTop >=
        el.target.scrollHeight
      ) {
        this.isPolicyChecked.temp = true;
      }
    },
  },
  created() {
    this.$store.dispatch('copytrade/req_getInfoExpert');
  },
};
</script>

<style lang="scss">
.tradeSignUp {
  padding-bottom: 100px;

  .tradeSignUp-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      @media (max-width: 768px) {
        width: 250px;
      }
    }
  }

  .line {
    width: 1px;
    height: 350px;
    background: rgba(93, 184, 251, 0.42);
  }

  .tradeSignUp-info {
    width: 100%;
    max-width: 550px;

    @media (max-width: 768px) {
      margin-top: 30px;
      text-align: center;
      max-width: inherit;
    }

    h3 {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 30px;
      margin-bottom: 40px;

      @media (max-width: 992px) {
        font-size: 23px;
      }
    }

    p {
      font-size: 20px;
      margin-bottom: 0px;
      margin-top: 20px;

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }

    .btn-signUp {
      margin-top: 40px;
      width: 100%;
      height: 50px;
      background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
      border-radius: 3px;
      position: relative;
      z-index: 1;

      @media (max-width: 768px) {
        width: 300px;
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        filter: blur(21px);
        z-index: -1;
      }
    }
  }

  .formSignUp {
    margin-top: 50px;

    .checkBoxIcon {
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border: 1px solid #0ec5ff;
        border-radius: 50%;

        &.check {
          width: 35px;
          height: 30px;
        }

        &.uncheck {
          width: 26px;
          height: 26px;
          cursor: pointer;
        }
      }
    }

    h3 {
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 30px;
      margin-bottom: 30px;

      @media (max-width: 992px) {
        font-size: 23px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .avatar {
      .select-images-box {
        width: 100%;
        height: auto;
        max-height: 400px;
        margin: auto;
      }

      .vue-advanced-cropper {
        max-width: 100%;
        max-height: 400px;
        height: auto;

        .vue-advanced-cropper__foreground {
          background: url(/img/bg-start.b64853e8.png),
            linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
          background-size: cover;
        }
      }

      .avatarEmpty {
        background: url(/img/bg-start.b64853e8.png),
          linear-gradient(180deg, #081e43 0%, rgba(1, 6, 15, 0.63) 100%);
        background-size: cover;
        padding: 45px 0;
        color: #424242;
        border-radius: 10px;
        height: 230px;
        width: 100%;
        position: relative;
        cursor: pointer;
        text-align: center;
        border: 1px solid #0ec5ff;
        display: flex;
        justify-content: center;
        align-items: center;

        .fileUpload {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 22;
          outline: none;
          opacity: 0;
        }

        p {
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }

      .btn-uploadAvatar {
        margin-top: 40px;
        width: 150px;
        height: 50px;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 3px;
        position: relative;
        z-index: 1;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 150px;
          height: 50px;
          background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
          filter: blur(21px);
          z-index: -1;
        }
      }
    }

    .labelInput {
      margin-bottom: 5px;
      font-weight: bold;

      @media (max-width: 992px) {
        font-size: 14px;
      }
    }

    .form-content {
      margin-top: 66px;

      .form-group {
        margin-bottom: 30px;
      }

      .vue__time-picker .dropdown ul::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
      }

      .vue__time-picker .dropdown ul::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }

      .vue__time-picker .dropdown ul::-webkit-scrollbar-thumb {
        background-color: #0ec5ff;
      }

      .vue__time-picker input {
        background-color: #1b2c57 !important;
        color: #fff;
        font-weight: 600;
        font-size: 17px;
        border-radius: 5px;
        border: 0 !important;

        &:focus {
          background: #0ec7ff55 !important;
          border-color: 1px solid #0ec5ff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem #0ec7ff55;
        }
      }

      .vue__time-picker .dropdown ul li:not([disabled]).active,
      .vue__time-picker .dropdown ul li:not([disabled]).active:hover {
        background: #0ec5ff;
      }
    }

    .btn-sendSignUp {
      width: 100%;
      height: 50px;
      background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
      border-radius: 3px;
      position: relative;
      z-index: 1;

      @media (max-width: 768px) {
        width: 300px;
      }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        filter: blur(21px);
        z-index: -1;
      }
    }
  }
}

#policy {
  .policy-div {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: visible;
    //   position: relative;
    padding: 5px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      background-color: #f5f5f5;
      // -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #ffcb12;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  .upDownIcon {
    animation-name: updown;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin: auto;
    color: #ffcb12;
    font-size: 35px;
  }

  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .btn-aktrade {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%) !important;
    border-radius: 3px;
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
      filter: blur(21px);
      z-index: -1;
    }
  }
}</style>